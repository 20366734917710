
import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";

import { createApp } from 'vue'
import App from './App.vue'

import "@/extensions/date.extension";
import "@/extensions/string.extension";
import "@/extensions/number.extension";

import router from './router'
import store from './store'

import TopNavigation from "@/components/TopNavigation.vue";
import Footer from "@/components/Footer.vue";
import SideNavigation from "@/components/SideNavigation.vue";
import CategoryItem from"@/components/CategoryItem.vue";
import JMUploadImages from "@/components/JMUploadImages.vue";
import JMUpload from "@/components/JMUpload.vue";
import PRCarouselV2 from "@/components/PRCarouselV2.vue";
import PRCarouselV3 from "@/components/PRCarouselV3.vue";
import PRCarousel from "@/components/PRCarousel.vue";




// AOS.init()
const app = createApp(App)
  // .use(Toast)
  .use(store)
  .use(router)
  app.component("TopNavigation", TopNavigation);
  app.component("AppFooter", Footer);
  app.component("SideNavigation", SideNavigation);
  app.component("CategoryItem", CategoryItem);
  app.component('JMUploadImages', JMUploadImages);
  app.component("JMUpload", JMUpload);
  app.component("PRCarouselV2", PRCarouselV2);
  app.component("PRCarouselV3", PRCarouselV3);
  app.component("PRCarousel", PRCarousel);
app.mount('#app')

