import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Public/Home.vue'
import Update from '@/views/Public/Update.vue'
import Aboutus from '@/views/Public/Aboutus.vue'
import Product from '@/views/Public/Product.vue'
import ProductDetail from '@/views/Public/ProductDetail.vue'
import Blog from '@/views/Public/Blog.vue'
import BlogDetail from '@/views/Public/BlogDetail.vue'
import CategoryDetail from '@/views/Public/CategoryDetail.vue'
import Service from '@/views/Public/Service.vue'
import ServiceDetail from '@/views/Public/ServiceDetail.vue'
import Contact from '@/views/Public/Contact.vue'

// 
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: '/update',
    name: 'Update',
    component: Update
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: Aboutus
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/product/:id',
    name: 'Product Detail',
    component: ProductDetail
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/blog/:id',
    name: 'Blog Detail',
    component: BlogDetail
  },
  {
    path: '/category/:id',
    name: 'Category Detail',
    component: CategoryDetail
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
  },
  {
    path: '/service/:id',
    name: 'Service Detail',
    component: ServiceDetail,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },

]
export default routes