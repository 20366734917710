
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { useStore } from "vuex";
import CategoryItem from "@/components/CategoryItem.vue";

@Options({
  components: {CategoryItem},
})
export default class Aboutus extends Vue {
  store = useStore()
  docs:Array<any> = []
  show = 5;
  page = 1;
  pages = 0;
  limit = 5;
  total = 5;
  search = "";
  ADDRESS = ADDRESS;
  doc: any = {};
  mounted() {
    this.fetchData();
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page)
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchData() {
    let query = {
      search: {
        nameTH: this.search != "" ? this.search : undefined
      },
      limit: this.show,
      page: this.page,
      sort: { createdAt: -1 }
    };
    axios
      .post(`${ADDRESS}/services/paginate`, query)
      .then((response) => {
        console.log(response.data)
        this.docs = response.data.docs
        this.total = response.data.totalDocs;
        this.pages = response.data.totalPages;
    });
  }
  initData() {
    axios
      .get(`${ADDRESS}/service/get/${this.$route.params.id}`)
      .then((response) => {
        console.log("result", response);
        this.doc = response.data.doc;
      });
  }
}
