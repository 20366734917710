
import { Options, Vue } from "vue-class-component";
import TopNavigation from "@/components/TopNavigation.vue";
import Footer from "@/components/Footer.vue";
import Facebook from "@/components/FacebookChat.vue";

import { useStore } from "vuex";
import axios from "axios";

@Options({
  components: {
    TopNavigation,
    Footer,
    Facebook,
  },
})
export default class Public extends Vue {
}
