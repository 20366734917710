import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Public from '../views/Public.vue'
import Portal from '../views/Portal.vue'
import Login from '../views/Login.vue'
import PublicRoutes from "./public"
import PortalRoutes from "./portal"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Public',
    component: Public,
    children: PublicRoutes
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    children: PortalRoutes
  },
]
import store from '@/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to,from,savedPosition) {
    return { top: 0 }
  }
})
// router.beforeEach((to, from, next) => {
//   if(to.path.search("/portal") === -1) next()
//   if (to.name === 'Login') next()
//   else if (store.state.isAuthentication) next()
//   else {
//     next({
//       path: '/login'
//     })
//   }
//   // next()

// })


export default router
