
import ADDRESS from "@/settings";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import draggable from "vuedraggable";
@Options({
  components: { draggable },
  props: {
    modelValue: Array,
    folder: String,
    label: String,
  },
})
export default class JMUploads extends Vue {
  ADDRESS = ADDRESS;
  files: any = {};
  modelValue!: Array<any>;
  filesArray: Array<any> = [];
  folder = "unsorted";
  uploadPercentage: any = 0;
  deleteItem(i: number) {
    this.filesArray.splice(i, 1);
  }
  checkDrop(e: any) {
    e.preventDefault();
  }
  async dropLink(e: any) {
    if (!Array.isArray(this.filesArray)) this.filesArray = [];
    console.log(e.dataTransfer.files);
    this.files = Array.from(e.dataTransfer.files);
    let length = this.filesArray.length;
    console.log({ length });
    for (const file of this.files) {
      console.log(file);
      this.filesArray.push({ path: "*", percent: 0 });
      let result = await this.uploadFile(file, length);
      this.filesArray[length].path = result.path;
      this.filesArray[length].percent = 100;
      this.filesArray[length].size = result.size;
      length++;
    }
    this.$emit("update:modelValue", this.filesArray);
    this.$emit("change", this.filesArray);
    this.files.forEach((file: any, i: number) => {
      // console.log(file)
      // this.uploadFile(file,i)
    });
  }
  dragend() {
    this.$emit("update:modelValue", this.filesArray);
    this.$emit("change", this.filesArray);
  }

  uploadFile(
    file: any,
    index: number
  ): Promise<{ path: string; size: number }> {
    console.log({ file, index });
    return new Promise((resolve, reject) => {
      var fileSize = file.size / 1024;
      if (fileSize > 250 * 1024) {
        alert("ไฟล์มีขนาดมากกว่า 250 MB กรุณาเลือกไฟล์ใหม่");
      } else {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const formData = new FormData();
        formData.set("name", `${this.folder ?? "unsorted"}`);
        formData.append("file", file);
        axios
          .post(`${this.ADDRESS}/upload/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent:any) {
              console.log("จะอัพโหลด", self.filesArray, self.filesArray[index]);
              self.filesArray[index].percent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }.bind(self),
          })
          .then(function (response: any) {
            console.log(response.data.path);
            resolve({ path: response.data.path, size: fileSize / 1024 });
          })
          .catch(function (err: Error) {
            console.log(err);
            reject({ path: "error", size: 0 });
          });
      }
    });
  }

  async uploadOneFile(ev: any) {
    console.log("okok");
    if (!Array.isArray(this.filesArray)) this.filesArray = [];
    this.filesArray.push({ path: "*", percent: 0 });
    let length = this.filesArray.length - 1;
    let result = await this.uploadFile(ev.target.files[0], length);
    this.filesArray[length].path = result.path;
    this.filesArray[length].percent = 100;
    this.filesArray[length].size = result.size;
    this.$emit("update:modelValue", this.filesArray);
    this.$emit("change", this.filesArray);
  }
  mounted() {
    this.filesArray = this.modelValue;
  }
  updated() {
    this.$nextTick(() => {
      let same = this.filesArray !== this.modelValue;
      if (same) {
        this.filesArray = this.modelValue;
      }
    });
  }
}
