
import { Options, Vue } from "vue-class-component";
// import { APIKEY } from "@/settings";
import axios from "axios";

@Options({
  components: {},
  props: {},
})
export default class App extends Vue {
  // beforeMount() {
  //   axios.interceptors.request.use(function (config) {
  //     const token = APIKEY;
  //     config!.headers!.apikey = token;
  //     config!.headers!.token = token;

  //     return config;
  //   });
  // }
}
