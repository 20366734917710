
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { ADDRESS } from "@/settings";

export default class AboutUs extends Vue {
  doc:any = {}
  address = ADDRESS;
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    let response = await axios.get(`${ADDRESS}/setting/get-by-name/aboutus`);
    this.doc = response.data.doc;
  }
  async submitForm() {
    await axios.patch(
      `${ADDRESS}/setting/update/${this.doc._id}`,
      this.doc
    ).then((response)=> {
      alert("แก้ไขข้อมูลแล้ว!")
    })
  }
}
