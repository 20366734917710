
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import { APIKEY } from "@/settings";
import "vue3-carousel/dist/carousel.css";
import FacebookChat from "@/components/FacebookChat.vue";
import PRCarouselV2 from "@/components/PRCarouselV2.vue";
import ADDRESS from "@/settings";
import JMLocaleHTML from "../../components/JMLocaleHTML.vue";
import CategoryItem from "@/components/CategoryItem.vue";
@Options({
  components: {
    FacebookChat,
    CategoryItem
    // PRCarouselV2,
  },
})
export default class Portal extends Vue {
  store = useStore();
  ADDRESS = ADDRESS;
  docs: Array<any> = [];
  blogs: Array<any> = []
  products: Array<any> = []
  categories: Array<any> = []
  services: Array<any> = []
  about: any = {};
  page = 1;
  // showDetail: boolean = false;

  mounted() {
    this.fetchData();
    this.fetchBlog();
    this.fetchService();
    this.fetchProduct()
    this.fetchCategory()
  }
  async fetchData() {
    let response = await axios.get(`${ADDRESS}/setting/get-by-name/home`);
    this.docs = response.data.doc;
  }
  fetchService() {
    let query = {
      limit: 4,
      page: this.page,
      sort: { createdAt: -1 },
    };
    axios.post(`${ADDRESS}/services/paginate`, query).then((response) => {
      console.log("result", response);
      this.services = response.data.docs;
    });
  }
  fetchCategory() {
    let query = {
      limit: 4,
      page: this.page,
      sort: { createdAt: -1 },
    };
    axios.post(`${ADDRESS}/categories/paginate`, query).then((response) => {
      console.log("result", response);
      this.categories = response.data.docs;
    });
  }
  fetchProduct() {
    let query = {
      limit: 4,
      page: this.page,
      sort: { createdAt: -1 },
    };
    axios.post(`${ADDRESS}/products/paginate`, query).then((response) => {
      console.log("result", response);
      this.products = response.data.docs;
    });
  }
  // async fetchProduct() {
  //   let query = {
  //     limit: 4,
  //     page: this.page,
  //     sort: { createdAt: -1 },
  //   };
  //   axios
  //     .post(`${ADDRESS}/products/paginate`, query)
  //     .then((response) => (this.products = response.data.doc));
  // }

  fetchBlog() {
    // let query = {
    //   limit: 4,
    //   page: this.page,
    //   sort: { createdAt: -1 },
    // };
    axios.get(`${ADDRESS}/blogs/list`).then((response) => {
      console.log("result", response);
      this.blogs = response.data.doc;
    });
  }
}
