import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import productMain from '@/views/Portal/product/Main.vue'
import productList from '@/views/Portal/product/List.vue'
import productForm from '@/views/Portal/product/Form.vue'
import contactMain from '@/views/Portal/contact/Main.vue'
import contactList from '@/views/Portal/contact/List.vue'
import contactForm from '@/views/Portal/contact/Form.vue'
import blogMain from '@/views/Portal/blog/Main.vue'
import blogList from '@/views/Portal/blog/List.vue'
import blogForm from '@/views/Portal/blog/Form.vue'
import AboutUs from '@/views/Portal/AboutUs.vue'
import Home from '@/views/Portal/Home.vue'
import serviceMain from '@/views/Portal/service/Main.vue'
import serviceList from '@/views/Portal/service/List.vue'
import serviceForm from '@/views/Portal/service/Form.vue'
import categoryMain from '@/views/Portal/category/Main.vue'
import categoryList from '@/views/Portal/category/List.vue'
import categoryForm from '@/views/Portal/category/Form.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'product',
    name: 'Product Main',
    component: productMain,
    children: [
      {
        path: '',
        name: 'Product List',
        component: productList
      },
      {
        path: 'create',
        name: 'Product Form',
        component: productForm
      },
      {
        path: 'edit/:id',
        name: 'Product Edit',
        component: productForm
      },
    ]
  },
  {
    path: 'service',
    name: 'Service Main',
    component: serviceMain,
    children: [
      {
        path: '',
        name: 'Service List',
        component: serviceList
      },
      {
        path: 'create',
        name: 'Service Form',
        component: serviceForm
      },
      {
        path: 'edit/:id',
        name: 'Service Edit',
        component: serviceForm
      },
    ]
  },
  {
    path: 'blog',
    name: 'Blog Main',
    component: blogMain,
    children: [
      {
        path: '',
        name: 'Blog List',
        component: blogList
      },
      {
        path: 'create',
        name: 'Blog Form',
        component: blogForm
      },
      {
        path: 'edit/:id',
        name: 'Blog Edit',
        component: blogForm
      },
    ]
  },
  {
    path: 'about',
    name: 'Portal About Us',
    component: AboutUs
  },
  {
    path: 'home',
    name: 'Portal Home',
    component: Home
  },
  {
    path: 'category',
    name: 'Category Main',
    component: categoryMain,
    children: [
      {
        path: '',
        name: 'Category List',
        component: categoryList
      },
      {
        path: 'create',
        name: 'Category Form',
        component: categoryForm
      },
      {
        path: 'edit/:id',
        name: 'Category Edit',
        component: categoryForm
      },
    ]
  },

]

export default routes