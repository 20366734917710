import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  class: "col-12 col-lg-3",
  "data-aos": "fade-right",
  "data-aos-offset": "300",
  "data-aos-delay": "150"
}
const _hoisted_4 = {
  class: "col-12 col-lg-9 align-items-center center-highlight-item bg-white p-4 rounded",
  "data-aos": "fade-up",
  "data-aos-offset": "300",
  "data-aos-delay": "150"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "text-center text-primary mt-4"
}
const _hoisted_7 = {
  key: 1,
  class: "text-center text-primary mt-4"
}
const _hoisted_8 = {
  key: 2,
  class: "text-center text-primary mt-4"
}
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { key: 5 }
const _hoisted_12 = { class: "m-4" }
const _hoisted_13 = {
  class: "col-12 align-items-center center-highlight-item",
  "data-aos": "fade-up",
  "data-aos-offset": "300",
  "data-aos-delay": "150"
}
const _hoisted_14 = { class: "d-flex justify-content-between" }
const _hoisted_15 = { class: "text-start text-primary" }
const _hoisted_16 = { class: "fw-bold" }
const _hoisted_17 = { class: "d-flex flex-wrap gap-3" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "card-body text-start" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryItem = _resolveComponent("CategoryItem")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CategoryItem)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          class: "img-fluid",
          src: `${_ctx.ADDRESS}/${_ctx.doc.image}`,
          alt: "Card image cap"
        }, null, 8, _hoisted_5),
        (_ctx.store.state.language==='th')
          ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.doc.nameTH), 1))
          : (_ctx.store.state.language==='ch')
            ? (_openBlock(), _createElementBlock("h4", _hoisted_7, _toDisplayString(_ctx.doc.nameCH), 1))
            : (_openBlock(), _createElementBlock("h4", _hoisted_8, _toDisplayString(_ctx.doc.nameEN), 1)),
        (_ctx.store.state.language==='th')
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.doc.descriptionTH), 1))
          : (_ctx.store.state.language==='ch')
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.doc.descriptionCH), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.doc.descriptionEN), 1)),
        _createElementVNode("section", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("h4", _hoisted_15, _toDisplayString(_ctx.store.getters.localized('product')), 1),
              _createVNode(_component_router_link, {
                class: "text-primary",
                to: "/product"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.store.getters.localized('more')) + " >>", 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  class: "text-decoration-none card border border-0 shadow",
                  style: {"width":"12rem"},
                  to: `/product/${product._id}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "img-fluid p-3 rounded",
                      src: product.images && product.images[0] && product.images[0].path ? `${_ctx.ADDRESS}/${product.images[0].path}` : '',
                      alt: "Card image cap",
                      style: {"width":"100%","height":"100%","aspect-ratio":"1/1"}
                    }, null, 8, _hoisted_18),
                    _createElementVNode("div", _hoisted_19, [
                      (_ctx.store.state.language==='th')
                        ? (_openBlock(), _createElementBlock("h5", _hoisted_20, _toDisplayString(product.nameTH), 1))
                        : (_ctx.store.state.language==='ch')
                          ? (_openBlock(), _createElementBlock("h5", _hoisted_21, _toDisplayString(product.nameCH), 1))
                          : (_openBlock(), _createElementBlock("h5", _hoisted_22, _toDisplayString(product.nameEN), 1))
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 256))
            ])
          ])
        ])
      ])
    ])
  ]))
}