
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { ADDRESS } from "@/settings";
import { AreaHTMLAttributes } from "vue";

export default class ServiceForm extends Vue {
  languages:Array<string> = []
  contacts:Array<object> = [
    {
      name: "Line",
      url: ""
    },
    {
      name: "Facebook",
      url: ""
    },
    {
      name: "WeChat",
      url: ""
    },

  ]
  doc:any = {
    languages: this.languages,
    contacts: this.contacts
  }
  address = ADDRESS;
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  async fetchData() {
    let response = await axios.get(`${ADDRESS}/service/get/${this.$route.params.id}`);
    this.doc = response.data.doc;
  }
  async submitForm() {
    if (this.$route.params.id != undefined) {
      axios.patch(`${ADDRESS}/service/update/${this.$route.params.id}`, {
        ...this.doc,
      }).then((response) => {
        alert("แก้ไขข้อมูลแล้ว!")
        this.$router.push('/portal/service')
      });
    } else {
      axios.post(`${ADDRESS}/service/create`, {
        ...this.doc,
      }).then((response) => {
        alert("บันทึกสำเร็จ!")
        this.$router.push('/portal/service')
      });
    }
  }

}
