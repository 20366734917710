import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "col-12 col-lg-3",
  "data-aos": "fade-right",
  "data-aos-offset": "300",
  "data-aos-delay": "150"
}
const _hoisted_3 = {
  class: "col-12 col-lg-9 align-items-center center-highlight-item bg-white p-5 rounded",
  "data-aos": "fade-up",
  "data-aos-offset": "300",
  "data-aos-delay": "150"
}
const _hoisted_4 = { class: "text-start text-secondary mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "text-start text-primary"
}
const _hoisted_6 = {
  key: 1,
  class: "text-start text-primary"
}
const _hoisted_7 = {
  key: 2,
  class: "text-start text-primary"
}
const _hoisted_8 = { class: "container w-50 mt-4" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "col mt-3" }
const _hoisted_11 = {
  key: 0,
  class: "text-start"
}
const _hoisted_12 = {
  key: 1,
  class: "text-start"
}
const _hoisted_13 = {
  key: 2,
  class: "text-start"
}
const _hoisted_14 = {
  key: 3,
  class: "mt-1 text-secondary text-end"
}
const _hoisted_15 = {
  key: 4,
  class: "mt-1 text-secondary text-end"
}
const _hoisted_16 = {
  key: 5,
  class: "mt-1 text-secondary text-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryItem = _resolveComponent("CategoryItem")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CategoryItem)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: "/blog" }, {
        default: _withCtx(() => [
          _createElementVNode("h6", _hoisted_4, " < < " + _toDisplayString(_ctx.store.getters.localized('allBlog')), 1)
        ]),
        _: 1
      }),
      (_ctx.store.state.language==='th')
        ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.doc.nameTH), 1))
        : (_ctx.store.state.language==='ch')
          ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.doc.nameCH), 1))
          : (_openBlock(), _createElementBlock("h4", _hoisted_7, _toDisplayString(_ctx.doc.nameEN), 1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          class: "img-fluid rounded",
          src: `${_ctx.ADDRESS}/${_ctx.doc.image}`,
          style: {"aspect-ratio":"1/1"}
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.store.state.language==='th')
          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.doc.descriptionTH), 1))
          : (_ctx.store.state.language==='ch')
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.doc.descriptionCH), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.doc.descriptionEN), 1)),
        (_ctx.store.state.language==='th')
          ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.store.getters.localized('createdAtBlog')) + " : " + _toDisplayString(new Date (_ctx.doc.createdAt).toThaiShort()), 1))
          : (_ctx.store.state.language==='ch')
            ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.store.getters.localized('createdAtBlog')) + " : " + _toDisplayString(new Date (_ctx.doc.createdAt).toEngShort()), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.store.getters.localized('createdAtBlog')) + " : " + _toDisplayString(new Date (_ctx.doc.createdAt).toEngShort()), 1))
      ])
    ])
  ]))
}