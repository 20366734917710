
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
})
export default class TopNavigation extends Vue {
  store = useStore();
  showSearch = false;
  search = "";
  docs: Array<any> = [];
  showDropDown = false;
  showNavbar = true;
  windowWidth = 10000;
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      this.onResize()
    });
    
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  @Watch("$route.path")
  onResize() {
    this.windowWidth = window.innerWidth;
    console.log("ww",this.windowWidth)
    if(this.windowWidth<576){
      this.showNavbar = false
    } else {
      this.showNavbar = true
    }
  }

  toggleNavBar() {
    this.showNavbar = !this.showNavbar;
  }
  show() {
    // alert("sd")
    if (!this.showSearch) this.showSearch = !this.showSearch;
    else this.showSearch = false;
  }

  @Watch("search")
  fetchData() {
    if (this.search.length < 3) return;
    let query = {
      search: {
        $or: [
          {
            nameTH: {
              $regex: this.search,
            },
          },
          {
            descriptionTH: {
              $regex: this.search,
            },
          },
        ],
      },
      limit: 4,
      page: 1,
      sort: { createdAt: -1 },
    };
    axios.post(`${ADDRESS}/products/paginate`, query).then((response) => {
      this.docs = response.data.docs;
      
    });
    // location.reload(); 
    // axios.get(`${ADDRESS}/blogs/paginate`, query).then((response) => {
    //   this.docs = response.data.docs;
    // });
  }

  pushToSearch(id: string) {
    this.docs = [];
    this.search = "";
    this.showSearch = false;
    this.$router.push(`/product/${id}`);
  }
  highlight(item: any) {
    const content: string = item.nameTH + ":" + item.descriptionTH;
    if (!this.search) {
      return content;
    }
    return content.replace(new RegExp(this.search, "gi"), (match) => {
      return '<span class="highlightSearchText">' + match + "</span>";
    });
  }
}
