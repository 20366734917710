
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import ADDRESS from "@/settings";

@Options({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props:{
    image:Array,
  }
})
export default class PRCarousel extends Vue {
  image!:any[]
  ADDRESS= ADDRESS
}
