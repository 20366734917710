/* eslint-disable */
import { createStore } from 'vuex'

export default createStore({
  state: {
    local: {
      en: {
        //Nav
        companyName: "Tu Amulet Thailand",
        product: 'Amulet',
        aboutus: 'About Us',
        blog: 'Blog',
        service: 'Service',
        contact: 'Contact',
        //Home
        home01:'Amulet rental service',
        home02:'One stop service',
        home03:'We have staff to give advice.',
        home04:'Popular Amulets',
        more:'More',
        readMore:'Read More',
        filter:'Filter Product',
        // Product
        allProduct:'All Product',
        codeProduct:'Code',
        detail:'Detail',
        temple:'Temple',
        region:'Region',
        // Blog
        allBlog:'All Blog',
        createdAtBlog:'Created At',
        // Service
        allService:'All Service',
        contect01:'Tu Amulet Thailand',
        contect02:'17/2 Ngamwongwan road, Thungsonghong, Laksi, Bangkok 10210'


      },
      th: {
        //nav
        companyName: "ตู่พระเครื่องไทยแลนด์",
        product: 'พระเครื่อง/เครื่องราง',
        aboutus: 'เกี่ยวกับเรา',
        blog: 'สาระน่ารู้',
        service: 'ติดต่อสอบถาม',
        contact: 'ติดต่อ/สอบถาม',
        //Home
        home01:'บริการเช่าพระเครื่อง',
        home02:'แบบครบวงจร',
        home03:'เรามีบริการเจ้าหน้าที่ให้คำปรึกษา',
        home04:'พระเด่นประจำร้าน',
        more:'ดูเพิ่มเติม',
        readMore:'อ่านเพิ่มเติม',
        filter:'สินค้าตามหมวดหมู่',
        // Product
        allProduct:'สินค้าทั้งหมด',
        codeProduct:'รหัสสินค้า',
        detail:'รายละเอียด',
        temple:'วัด',
        region:'ภาค',
        // Product
        allBlog:'พระเครื่อง/เครื่องรางทั้งหมด',
        createdAtBlog:'วันที่เผยแพร่',
        // Service
        allService:'บริการทั้งหมด',
        // Contect
        contect01:'Tu Amulet Thailand',
        contect02:'17/2 ถนนงามวงศ์วาน แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10210'
      },
      ch: {
        //nav
        companyName: "Tu Amulet Thailand",
        product: '护身符',
        aboutus: '关于我们',
        blog: '博客',
        service: '服务',
        contact: '接触',
        //Home
        home01:'护身符租赁服务',
        home02:'交钥匙工程',
        home03:'我们有咨询服务',
        home04:'店里的杰出和尚',
        more:'更多的',
        readMore:'阅读更多',
        filter:'筛选',
        // Product
        allProduct:'所有产品',
        codeProduct:'代码',
        detail:'细节',
        temple:'寺庙',
        region:'地区',
        // Product
        allBlog:'所有博客',
        createdAtBlog:'创建于',
        // Service
        allService:'所有服务',
        // Contect
        contect01:'Tu Amulet Thailand',
        contect02:'17/2 Ngamwongwan road, Thungsonghong, Laksi, Bangkok 10210'

      }
    },
    language: "th"
  },
  getters: {
    localized: (state) => (key: any) => {
      try {
        //@ts-ignore
        return state.local[state.language][key]
      } catch (error) {
        return ""
      }
    }
  },
  mutations: {
    switchLanguage(state, payload) {
      if (state.language === 'th') state.language = 'en'
      else if(state.language === 'th',state.language === 'en') state.language = 'ch'
      else state.language = 'th'
    },
  },
  actions: {
  },
  modules: {
  }
})
