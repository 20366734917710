import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container-fluid d-flex w-100 h-100 p-0 d-flex flex-column bg-light",
  style: {"min-height":"100vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavigation = _resolveComponent("TopNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Facebook = _resolveComponent("Facebook")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopNavigation),
    _createVNode(_component_router_view, { style: {"flex-grow":"1"} }),
    _createVNode(_component_Facebook),
    _createVNode(_component_Footer)
  ]))
}