import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row p-4" }
const _hoisted_2 = { class: "text-start text-primary" }
const _hoisted_3 = {
  class: "form-select mt-1",
  "aria-label": "Default select example"
}
const _hoisted_4 = { selected: "" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.store.getters.localized('filter')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        class: "text-decoration-none",
        to: `/category/${category._id}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("select", _hoisted_3, [
            _createElementVNode("option", _hoisted_4, [
              (_ctx.store.state.language==='th')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(category.nameTH), 1))
                : (_ctx.store.state.language==='ch')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(category.nameCH), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(category.nameEN), 1))
            ])
          ])
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 256))
  ]))
}