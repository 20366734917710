
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { ADDRESS } from "@/settings";
import { AreaHTMLAttributes } from "vue";

export default class CategoryForm extends Vue {
  doc:any = {}
  address = ADDRESS;
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  async fetchData() {
    let response = await axios.get(`${ADDRESS}/category/get/${this.$route.params.id}`);
    this.doc = response.data.doc;
  }
  async submitForm() {
    if (this.$route.params.id != undefined) {
      axios.patch(`${ADDRESS}/category/update/${this.$route.params.id}`, {
        ...this.doc,
      }).then((response) => {
        alert("แก้ไขข้อมูลแล้ว!")
        this.$router.push('/portal/category')
      });
    } else {
      axios.post(`${ADDRESS}/category/create`, {
        ...this.doc,
      }).then((response) => {
        alert("บันทึกสำเร็จ!")
        this.$router.push('/portal/category')
      });
    }
  }

}
