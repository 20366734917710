
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import { ADDRESS } from "@/settings";
import L, { Icon } from "leaflet";

@Options({
  components: {},
})
export default class PortalHome extends Vue {
  store = useStore()
  doc: any = {};
  services: Array<any> = []
  address = ADDRESS;
  isMap = true
  mymap: any;
  layer: any;
  zoom = 16;
  
  switchToggle() {
    this.isMap = !this.isMap
    if(this.isMap){
      this.$nextTick(()=>{

      this.initMap()
      this.renderMap();
      })
    }
  }
  location:any = {
    coordinates:[100.5496855, 13.8515746]
  }
  mounted() {
    this.fetchData();
    this.fetchService();
    this.initMap();
    this.renderMap();

  }
  async fetchData() {
    let response = await axios.get(`${ADDRESS}/setting/get-by-name/contact`);
    this.doc = response.data.result;
    this.doc.text1EN = "No. 87 Moo 11, Bueng Sub-district, Khwao Sinrin District Surin Province"
  }
  fetchService() {
    axios.post(`${ADDRESS}/services/paginate`).then((response) => {
      console.log("result", response);
      this.services = response.data.docs;
    });
  }
  initMap() {
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }
  renderMap() {
    const coordinates = this.location.coordinates;
    var mymap = L.map("map").setView(
      [coordinates[1], coordinates[0]],
      this.zoom
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mymap);
    var layerGroup = L.layerGroup().addTo(mymap);
    this.layer = layerGroup;
    this.mymap = mymap;
    this.markerRender();
    mymap.on("zoomend", () => {
      this.zoom = mymap.getZoom();
      this.markerRender();
    });
  }
  markerRender() {
    const coordinates = this.location.coordinates;
    this.layer.clearLayers();
    let content = `
    <a href="https://www.google.com/maps?saddr=My+Location&daddr=13.766447852042793,100.57296924653227" target="_blank">
    <img src="${require("@/assets/location-dot.svg")}" width="20" height"20" style="position:relative; transform:translate(-50%,-50%);" alt="">`;
    L.marker([coordinates[1], coordinates[0]], {
      icon: new L.DivIcon({
        className: "my-div-icon",
        html: content,
      }),
    }).addTo(this.layer);
  }
}
