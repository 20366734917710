
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
// import ADDRESS from "@/settings";

@Options({
  components: {},
})
export default class Footer extends Vue {
  store = useStore();
}
