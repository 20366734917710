
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { ADDRESS } from "@/settings";

@Options({
  components: {
  },
})
export default class Home extends Vue {
  doc:any = {}
  address = ADDRESS;
  blogs: Array<any> = []
  products: Array<any> = []
  mounted() {
    this.fetchData();
    this.fetchBlog();
    this.fetchProduct()
;  }
  async fetchData() {
    let response = await axios.get(`${ADDRESS}/setting/get-by-name/home`);
    this.doc = response.data.doc;
  }
  async submitForm() {
    await axios.patch(
      `${ADDRESS}/setting/update/${this.doc._id}`,
      this.doc
    ).then((response)=> {
      alert("แก้ไขข้อมูลแล้ว!")
    })
  }
  async fetchBlog() {
    axios
      .get(`${ADDRESS}/blogs/list`)
      .then((response) => (this.blogs = response.data.doc));
  }
  async fetchProduct() {
    axios
      .get(`${ADDRESS}/products/list`)
      .then((response) => (this.products = response.data.doc));
  }


}
