
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import { APIKEY } from "@/settings";
import "vue3-carousel/dist/carousel.css";
import FacebookChat from "@/components/FacebookChat.vue";
import PRCarouselV2 from "@/components/PRCarouselV2.vue";
import ADDRESS from "@/settings";
import JMLocaleHTML from "../../components/JMLocaleHTML.vue";
import CategoryItem from "@/components/CategoryItem.vue";
@Options({
  components: {
    FacebookChat,
    CategoryItem
    // PRCarouselV2,
  },
})
export default class Portal extends Vue {
  store = useStore();
  ADDRESS = ADDRESS;
  doc: any = {};
  docs: Array<any> = [];
  blogs: Array<any> = []
  products: Array<any> = []
  categories: Array<any> = []
  search = "";
  page = 1;
  pages = 0;
  limit = 5;
  total = 5;
  show = 9999;
  // showDetail: boolean = false;

  mounted() {
    this.fetchData();
    this.fetchBlog();
    this.fetchProduct()
    this.fetchCategory()
  }
  async fetchData() {
    axios
      .get(`${ADDRESS}/category/get/${this.$route.params.id}`)
      .then((response) => {
        console.log("result", response);
        this.doc = response.data.doc;
      });
  }
  fetchCategory() {
    let query = {
      limit: 4,
      page: this.page,
      sort: { createdAt: -1 },
    };
    axios.post(`${ADDRESS}/categories/paginate`, query).then((response) => {
      console.log("result", response);
      this.categories = response.data.docs;
    });
  }
  async fetchProduct() {

    let query = {
      search: {
        category:this.$route.params.id
      },
      limit: 99,
      // sort: { category: this.doc._id }
    };
    axios
      .post(`${ADDRESS}/products/paginate`, query)
      .then((response) => {
        console.log(response.data)
        this.products = response.data.docs
        this.total = response.data.totalDocs;
        this.pages = response.data.totalPages;
    });
  }

  fetchBlog() {
    axios.get(`${ADDRESS}/blogs/list`).then((response) => {
      console.log("result", response);
      this.blogs = response.data.doc;
    });
  }
}
