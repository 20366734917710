
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { useStore } from "vuex";
import { ADDRESS } from "@/settings";
// import bcrypt from 'bcryptjs'
@Options({
  components: {},
})
export default class Login extends Vue {
  username = ""
  password = ""
  store = useStore()
  address = ADDRESS;
  doc: any = {};
  users: Array<any> = [
    {
      username: "noppadolwe",
      password: "nsns1991",
    },
    {
      username: "developer",
      password: "gd8c5s4e",
    }
  ]
  submitLogin() {
    let found = this.users.find((el) => el.username == this.username);
    if (found != undefined) {
      if (found.password == this.password) {
        this.store.commit(
          "login",
        );
        alert("ลงชื่อเข้าใช้ถูกต้อง");
        this.$router.push("/portal/home");
      } else {
        alert("รหัสผ่านไม่ถูกต้อง");
      }
    } else {
      alert("ไม่พบชื่อผู้ใช้");
    }
    
  }
  // users: Array<any> = [
  //   {
  //     username: "developer",
  //     password: "$2a$10$kdcOTTbIv7lGe7YIymfNauF6ISAQo9kIlBpg8oGYU7hU6vO58JNzK",
  //   },
  //   {
  //     username: "admin88",
  //     password: "$2a$10$N0g5ECciYdRNfpwOvmkaxORQIL3JdSveLnxMl98oGa.0gJ43edPd6",
  //   },
  //   {
  //     username: "admin99",
  //     password: "$2a$10$OHkWxPZoTd1a2X2kcXUw4eT/cIukSjo.vWBadZ651ISGqPBXqovae",
  //   },
  // ];
  // salt = bcrypt.genSaltSync(10);
  // hashed = "";
  // generatePassword(){
  //   bcrypt.genSalt(10, (err, salt) => {
  //     bcrypt.hash(this.password, salt,(err, hash) => {
  //       if (err){
  //         console.error(err)
  //         return 
  //       }
  //       this.hashed = hash
  //       return hash
  //       console.log(hash)
  //     });
  //   }
  //   );
  // }

  // submitLogin(){
  //   let found = this.users.find((el) => el.username == this.username);
  //   console.log(found)
  //   bcrypt.compare(this.password, found.password, (err, res) => {
  //     console.log(res)
  //   // res === true
  //     if (found != undefined){
  //       if(res === true){
  //       this.store.commit(
  //         "login",
  //       );
  //       alert("ลงชื่อเข้าใช้ถูกต้อง");
  //       this.$router.push("/portal");
  //       }else{
  //         alert("รหัสผ่านไม่ถูกต้อง");
  //       }
  //     }else {
  //     alert("ไม่พบชื่อผู้ใช้");
  //   }

  //   });
  // }

}

