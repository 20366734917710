
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { ADDRESS } from "@/settings";

@Options({
  components: {
  },
})
export default class ProductForm extends Vue {
  images:Array<string> = []
  doc:any = {
    images: this.images
  }
  address = ADDRESS;
  categories: Array<any> = []
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
    this.fetchCategory();
  }
  async fetchData() {
    let response = await axios.get(`${ADDRESS}/product/get/${this.$route.params.id}`);
    this.doc = response.data.doc;
  }
  async submitForm() {
    if (this.$route.params.id != undefined) {
      axios.patch(`${ADDRESS}/product/update/${this.$route.params.id}`, {
        ...this.doc,
      }).then((response) => {
        alert("แก้ไขข้อมูลแล้ว!")
        this.$router.push('/portal/product')
      });
    } else {
      axios.post(`${ADDRESS}/product/create`, {
        ...this.doc,
      }).then((response) => {
        alert("บันทึกสำเร็จ!")
        this.$router.push('/portal/product')
      });
    }
  }
  async fetchCategory() {
    axios
      .get(`${ADDRESS}/categories/list`)
      .then((response) => (this.categories = response.data.doc));
  }
}
