
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { useStore } from "vuex";
import CategoryItem from "@/components/CategoryItem.vue";

@Options({
  components: {CategoryItem},
})
export default class Aboutus extends Vue {
  store = useStore()
  docs:Array<any> = []
  show = 5;
  page = 1;
  pages = 0;
  limit = 5;
  total = 5;
  search = "";
  ADDRESS = ADDRESS;
  mounted() {
    this.fetchData();
  }
  isNewItem(item:any) {
    const currentDate = new Date();
    const createDate = new Date(item.createDate);
    const timeDifference = currentDate.getTime() - createDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    const isNew = daysDifference <= 7; // Consider items created within the last 7 days as "New"
    return isNew;
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page)
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchData() {
    let query = {
      search: {
        $or: [
          {
            code:
              this.search != ""
                ? this.search
                : undefined,
          },
          {
            nameTH:
              this.search != ""
                ? this.search
                : undefined,
          },
        ],
      },
      limit: this.show,
      page: this.page,
      sort: { createdAt: -1 }
    };
    axios
      .post(`${ADDRESS}/products/paginate`, query)
      .then((response) => {
        console.log(response.data)
        this.docs = response.data.docs
        this.total = response.data.totalDocs;
        this.pages = response.data.totalPages;
    });
  }
}
