import { DateTime } from "luxon";

export {};

declare global {
  interface String {
    capitalize(): string;
    formatComma(): string;
    formatCizitenId(): string;
    formatThai():string;
    formatMonthYear():string;
    toThaiShort():string;
    toEngShort():string;
    toThaiLong():string;
  }
  interface Number {
    formatComma(): string;
    formatFull(): string;
    formatMB(): string;
  }
}

String.prototype.formatThai = function(): string {
  const array = { "1": "๑", "2": "๒", "3": "๓", "4": "๔", "5": "๕", "6": "๖", "7": "๗", "8": "๘", "9": "๙", "0": "๐" };
  let str: string = this.toString();
  Object.keys(array).forEach((val) => {
    str = str.split(val).join(array[val as keyof typeof array]);
  });
  return str;
};
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
String.prototype.formatComma = function() {
  return this.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
String.prototype.formatCizitenId = function() {
  try {
    return this.replace(
      /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/g,
      "$1-$2-$3-$4-$5"
    );
  } catch (err) {
    return "";
  }
};
String.prototype.formatMonthYear = function(){
  try {  
    const dt = DateTime.fromISO(this as string)
    const fm = dt.toFormat('MMM yy');
    if(fm=="Invalid DateTime") return "-"
    else return fm
  } catch (error) {
    return "-"
  }
}
String.prototype.toEngShort = function(){
  try {  
    const dt = DateTime.fromISO(this as string)
    const fm = dt.setLocale("en").reconfigure({ outputCalendar: "buddhist" }).toFormat('d MMM yy');
    if(fm=="Invalid DateTime") return "-"
    else return fm
  } catch (error) {
    return "-"
  }
}

String.prototype.toThaiShort = function(){
  try {  
    const dt = DateTime.fromISO(this as string)
    const fm = dt.setLocale("th").reconfigure({ outputCalendar: "buddhist" }).toFormat('d MMM yy');
    if(fm=="Invalid DateTime") return "-"
    else return fm
  } catch (error) {
    return "-"
  }
}

String.prototype.toThaiLong = function(){
  try {  
    const dt = DateTime.fromISO(this as string).setLocale("th").reconfigure({ outputCalendar: "buddhist" })
    const diff = DateTime.now().diff(dt, ["years", "months", "days", "hours"])

    // console.log(diff.toObject())
    const fm = dt.toFormat('d MMM yy');
    if(fm=="Invalid DateTime") return "-"
    else return fm + `\r\n (${Math.floor(diff.years)} ปี ${Math.floor(diff.months)} เดือน)`
  } catch (error) {
    return "-"
  }
}


Number.prototype.formatComma = function() {
  return Math.round(this as number).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatFull = function() {
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatMB = function() {
  const num = this as number
  return (num/1000000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
