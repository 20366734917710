
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
// import { APIKEY } from "@/settings";

@Options({
  components: {
  },
})
export default class Portal extends Vue {

  // beforeMount() {
  //   axios.interceptors.request.use((config)=> {
  //     const token = APIKEY;
  //     config!.headers!.apikey = token;
  //     config!.headers!.token = token;
  //     // config!.headers!.authorization = `Bearer ${this.$store.state.jwt}`;

// <Question ID="1" Shortcut="chapter 1" Order="" ElementType="chapter" >
// <LongCaption>//     return config;</LongCaption>
// </Question>
  //   });
  // }

}
