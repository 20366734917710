
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class CategoryItem extends Vue {
  store = useStore()
  doc: any = {};
  address = ADDRESS;
  categories: Array<any> = []
  page = 1;
  mounted() {
    this.fetchCategory()
  }
  fetchCategory() {
    let query = {
      limit: 4,
      page: this.page,
      sort: { createdAt: -1 },
    };
    axios.post(`${ADDRESS}/categories/paginate`, query).then((response) => {
      console.log("result", response);
      this.categories = response.data.docs;
    });
  }
}
  