
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { useStore } from "vuex";
import CategoryItem from "@/components/CategoryItem.vue";

@Options({
  components: {CategoryItem},
})
export default class BlogDetail extends Vue {
  store = useStore()
  doc: any = {};
  ADDRESS = ADDRESS;
  mounted() {
    this.fetchData();
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/blog/get/${this.$route.params.id}`)
      .then((response) => {
        console.log("result", response);
        this.doc = response.data.doc;
      });
  }
  // async fetchData() {
  //   let response = await axios.get(`${ADDRESS}/setting/get-by-name/aboutus`);
  //   this.doc = response.data.result;
  // }
}
